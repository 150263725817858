import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script type="text/plain" class="optanon-category-4" 
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s);\njs.id = id;\njs.src = \"//connect.facebook.net/pl_PL/all.js#xfbml=1&appId=654267651256301\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));",
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: "\nvar ThemeDir = 'themes/simple';\n",
          }}
        />

        <div className="boper boper-hb">
          <div className="wrapper">
            <header
              style={{
                height: "120px",
              }}
            >
              <div
                style={{
                  height: "67px",
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/index.php/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a
                    href="/site/index.php/o-neutrogenie/kim-jestesmy/"
                    className="link"
                  >
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a
                    href="/site/index.php/see-whats-possible/"
                    className="link"
                  >
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a
                    href="/site/index.php/skora-z-niedoskonalosciami/"
                    className="link"
                  >
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px",
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none",
                  }}
                >
                  <a href="/site/index.php/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/index.php/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/index.php/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
          </div>
          <div className="hydroboots">
            <div
              className="section"
              style={{
                background: "#eceff4",
                "-webkit-box-shadow": "0px 5px 15px 0px rgba(0,0,0,0.1)",
              }}
            >
              <a href="/cellular-boost/">
                <img
                  src="/themes/simple/img/cellular/hb_banner.png"
                  style={{
                    display: "block",
                    "min-width": "960px",
                    width: "100%",
                    "max-width": "1360px",
                    margin: "0 auto",
                  }}
                />
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <ol className="mainboxes" id="mainboxes">
                <li>
                  <div className="case_prod_in first">
                    <a className="various1" href="#inline1">
                      <img
                        src="/site/index.php/assets/Uploads/reklama-tv.png"
                        alt="reklama tv"
                      />
                    </a>
                    <a className="various1 title" href="#inline1">
                      <h2>Zobacz spot TV</h2>
                    </a>
                    <div className="newChanges">
                      <p>
                        Zobacz naszą najnowszą reklamę telewizyjną.
                        <br />
                        <br />
                      </p>{" "}
                    </div>
                    <p>
                      <a id="various2" className="button" href="#inline1">
                        więcej
                      </a>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "none",
                    }}
                  >
                    <div
                      id="inline1"                      
                    >
                      <video
                        id="player_a"
                        className="projekktor"
                        poster="themes/simple/projekktor/media/hydroboost.png"
                        title="this is Projekktor"
                        width="640"
                        height="385"
                        controls
                      >
                        <source
                          src="/site/index.php/themes/simple/projekktor/media/JNJ_NEUTROGENA_HYDROBOOST_ONLINE_001.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </li>
                <li className="middle">
                  <a
                    href="/site/index.php/bad-piekna/skora-sucha/#aboutAnchor"
                    className="photo"
                    id="various1"
                  >
                    <img
                      src="/site/index.php/assets/Uploads/case-norm.png"
                      alt="case norm"
                    />
                  </a>
                  <a
                    href="/site/index.php/bad-piekna/skora-sucha/#aboutAnchor"
                    className="title"
                  >
                    <p>Pielęgnacja suchej skóry</p>
                  </a>
                  <div className="newChanges">
                    <p>
                      Nawilżona i dobrze odżywiona od wewnątrz skóra jest
                      wizytówką każdej zadbanej kobiety.
                    </p>
                    <p>
                      <a
                        className="button"
                        href="/site/index.php/bad-piekna/sucha-skora/"
                      >
                        więcej
                      </a>
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    height: "460px",
                  }}
                >
                  <div className="photo" id="products2">
                    <div className="slides_container">
                      <div id="box_slide">
                        <a href="/site/index.php/bad-piekna/twarz/#aboutAnchor">
                          <img
                            src="/site/index.php/assets/Uploads/twarz2.jpg"
                            alt="twarz2"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/site/index.php/bad-piekna/twarz/#aboutAnchor"
                            className="title"
                          >
                            <p>Pielęgnacja twarzy</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Chcesz mieć ładną i zdrową cerę? Dowiedz się co na
                              ma nią wpływ.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/site/index.php/bad-piekna/pielegnacja-twarzy/#aboutAnchor"
                              >
                                więcej
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/site/index.php/bad-piekna/usta/#aboutAnchor">
                          <img
                            src="/site/index.php/assets/Uploads/lips.jpg"
                            alt="lips"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/site/index.php/bad-piekna/usta/#aboutAnchor"
                            className="title"
                          >
                            <p>Pielęgnacja ust</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Usta są najczęściej zaniedbywaną częścią naszego
                              ciała. Co trzeba o nich wiedzieć?&nbsp;
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/site/index.php/bad-piekna/pielegnacja-ust/#aboutAnchor"
                              >
                                więcej
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/site/index.php/bad-piekna/pielegnacja-doni/#aboutAnchor">
                          <img
                            src="/site/index.php/assets/Uploads/dlonie4.jpg"
                            alt="dlonie4"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/site/index.php/bad-piekna/pielegnacja-doni/#aboutAnchor"
                            className="title"
                          >
                            <p>Pielęgnacja dłoni</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Jak skutecznie pielęgnować dłonie i zachować jak
                              najdłużej ich młody wygląd?
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/site/index.php/bad-piekna/pielegnacja-doni/#aboutAnchor"
                              >
                                więcej
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/site/index.php/bad-piekna/stopy/#aboutAnchor">
                          <img
                            src="/site/index.php/assets/Uploads/stopy3.jpg"
                            alt="stopy3"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/site/index.php/bad-piekna/stopy/#aboutAnchor"
                            className="title"
                          >
                            <p>Pielęgnacja stóp</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Rytuał pielęgnacyjny, który pozwoli Ci cieszyć się
                              pięknymi stopami przez cały rok.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/site/index.php/bad-piekna/pielegnacja-stop/#aboutAnchor"
                              >
                                więcej
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="box_slide">
                        <a href="/site/index.php/bad-piekna/ciao/#aboutAnchor">
                          <img
                            src="/site/index.php/assets/Uploads/ciao.jpg"
                            alt="ciao"
                          />
                        </a>
                        <div id="text_slide">
                          <a
                            href="/site/index.php/bad-piekna/ciao/#aboutAnchor"
                            className="title"
                          >
                            <p>Pielęgnacja ciała</p>
                          </a>
                          <div className="newChanges">
                            {" "}
                            <p>
                              Zdrowa i piękna skóra wymaga przemyślanej
                              pielęgnacji każdego dnia.
                            </p>
                            <p>
                              <a
                                className="button"
                                href="/site/index.php/bad-piekna/pielegnacja-ciala/#aboutAnchor"
                              >
                                więcej
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <div id="social_id_17"></div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/" className="logo2" />
              <a href="/site/index.php/nota-prawna/">Nota Prawna</a>
              <a href="/site/index.php/polityka-prywatnosci/">
                Polityka Prywatności
              </a>
              <a href="/site/index.php/cookies/">Polityka cookies</a>
              <a href="/site/index.php/kontakt/">Zachęcamy do kontaktu</a>
              <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav>
            <a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');",
          }}
        />
        <script type="text/plain" class="optanon-category-4" 
          dangerouslySetInnerHTML={{
            __html:
              "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n",
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">',
          }}
        />
        <script src="/site/index.php/themes/simple/js/slides.min.jquery.js" />
        <script src="/site/index.php/themes/simple/js/slide.js" />
      </div>
    );
  }
}

export default Page;
